<template>
    <a-card>
        <a-page-header
            title='广告组管理'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row :gutter="[5,0]" type="flex" justify="start">
                <a-col :span="8">
                    <a-form-model-item label="查询周期">
                        <a-range-picker
                            v-model="pickerTime"
                            value-format="YYYY-MM-DD"
                            :disabled-date="disabledDate"
                            :allow-clear="false"
                            @calendarChange="calendarChange"
                            @openChange="openChange"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告组">
                        <a-input
                            v-model="form.adGroupName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="系统开关状态">
                        <a-select
                            v-model="form.sysOptStatus"
                            placeholder="请选择"
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in switchState" :key="item.id">{{ item.label }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告组状态">
                        <a-select
                            mode="multiple"
                            v-model="form.adGroupStatusList"
                            placeholder="请选择"
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in statusList" :key="item.code">{{ item.msg }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告组品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                            @change="onChangePrincipal"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告组车系">
                        <a-select
                            mode="multiple"
                            v-model="form.carSeriesIdList"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col span="12">
                    <a-space>
                        <a-button type="primary" @click="batchByAdGroupIds('valid')">批量启用</a-button>
                        <a-button type="primary" @click="batchByAdGroupIds('disable')">批量暂停</a-button>
                    </a-space>
                </a-col>
                <a-col span="12" class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :row-selection="rowSelection"
            :pagination='false'
            :scroll="{x: 2000}"
            bordered
        >
            <div
                slot="operation"
                slot-scope="text, record"
            >
                <base-button
                    :type="'link'"
                    :title="'详情'"
                    @onClickBtn="openDetailDrawer(record, 1)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'计划'"
                    @onClickBtn="jumpPlanPage(record.adGroupId)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'更多操作'"
                    @onClickBtn="openActionModal(record)"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />


        <a-drawer
            placement="right"
            :closable="false"
            :visible="detailVisible"
            :width="1000"
            @close="closeDetailDrawer"
        >
            <a-tabs v-model="activeIndex">
                <a-tab-pane :key="1" tab="详情">
                    <div class="box">
                        <div class="title">广告平台信息</div>
                        <div class="row">
                            <div class="name">广告组：</div>
                            <div class="detail">{{ dataDetail.adGroupName || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告组ID：</div>
                            <div class="detail">{{ dataDetail.adGroupId || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告组预算：</div>
                            <div class="detail">{{ dataDetail.budget || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告组预算类型：</div>
                            <div class="detail">{{ dataDetail.budgetMode == 1 ? '不限' : dataDetail.budgetMode == 2 ? '日预算' : '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告组推广目的：</div>
                            <div class="detail">{{ dataDetail.landingTypeDesc || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告组创建时间：</div>
                            <div class="detail">{{ dataDetail.adGroupCreateTime || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告组修改时间：</div>
                            <div class="detail">{{ dataDetail.adGroupModifiedTime || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">平台账套ID：</div>
                            <div class="detail">{{ dataDetail.adPlatformAccountId || '-' }}</div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="title">皮皮慧投定义信息</div>

                        <div class="row">
                            <div class="name">系统创建：</div>
                            <div class="detail">{{ dataDetail.createSource == 1 ? '是' : '否' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">系统管控：</div>
                            <div class="detail">{{ dataDetail.isSysControl ? '是' : '否' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告组品牌：</div>
                            <div class="detail">{{ dataDetail.principalName || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告组车系：</div>
                            <div class="detail">{{ dataDetail.carSeriesName || '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">投放结构：</div>
                            <div class="detail">{{ dataDetail.deliveryStruct == 1 ? '单车系投放结构' : '-' }}</div>
                        </div>
                        <div class="row">
                            <div class="name">广告主：</div>
                            <div class="detail">{{ dataDetail.advertiserName || '-' }}</div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="日志">
                    <div style="margin: 20px 0">广告组：{{ rowData.adGroupName || '-' }}</div>
                    <div>广告组ID：{{ rowData.adGroupId || '-' }}</div>
                    <a-table
                        :columns="logColumns"
                        :data-source="logsList"
                        :rowKey="(record, index) => index"
                        :pagination='false'
                        bordered
                        style="margin-top: 20px;"
                    ></a-table>
                    <base-pagination
                        :currentPage="paginationLog.current"
                        :pageSize="paginationLog.pageSize"
                        :total="paginationLog.total"
                        @onChange="handlePaginationLogChange"
                        @onShowSizeChange="handlePaginationLogChange"
                    />
                </a-tab-pane>
            </a-tabs>
        </a-drawer>

        <!-- 更多操作  -->
        <a-modal
            :visible="actionVisible"
            :footer="null"
            title="更多操作"
            @cancel="handleActionCancel"
        >
            <base-button
                :type="'link'"
                :title="'操作日志'"
                @onClickBtn="openDetailDrawer(rowData, 2)"
            ></base-button>
            <base-button
                :type="'link'"
                :title="'删除'"
                @onClickBtn="deleteGroup(rowData.id)"
                v-if="rowData.adGroupStatus != 'delete'"
            ></base-button>
        </a-modal>
    </a-card>
</template>

<script>
import moment from 'moment'

const switchState = [
    { id: 1, label: '启用' },
    { id: 2, label: '暂停' },
    { id: 3, label: '删除' },
]

const renderContent = (data, index) => {
    const obj = {
        children: data,
        attrs: {},
    };
    if (index === 0) {
        obj.attrs.colSpan = 0;
    }
    return obj;
}

export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            colSpan: 12,
            form: {
                adGroupStatusList: []
            },
            pickerTime: [moment(), moment()],
            selectStartDay: '',
            selectStatusList: [],
            loading: false,
            columns: [
                // begin - 展示合计
                // {
                //     align: 'center',
                //     title: '系统开关状态',
                //     dataIndex: 'sysOptStatusDesc',
                //     customRender: (text, row, index) => {
                //         if (index > 0) {
                //             return <span>{text || '-'}</span>;
                //         }
                //         return {
                //             children: <span>合计</span>,
                //             attrs: {
                //                 colSpan: 6,
                //             },
                //         }
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '广告组状态',
                //     dataIndex: 'adStatusDesc',
                //     customRender: (text, row, index) => {
                //         return renderContent(text, index)
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '广告组广告组ID',
                //     customRender: (text, row, index) => {
                //         let data = <div>
                //                 <div>{ row.adPlanName || '-' }</div>
                //                 <div class="txt">{row.adPlanId || '-'}</div>
                //             </div>
                //         return renderContent(data, index)
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '操作',
                //     customRender: (text, row, index) => {
                //         let data = <a on-click={() => {this.openDetailDrawer(row.id)}}>详情</a>

                //         return renderContent(data, index)
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '消费（元）',
                //     dataIndex: 'totalCost',
                    // customRender: (text, row, index) => {
                    //     return (text ?? '') !== '' ? text : '-'
                    // }
                // },
                // {
                //     align: 'center',
                //     title: '目标转化数',
                //     dataIndex: 'principalName1',
                //     customRender: (text, row, index) => {
                //         let data = text || '-'

                //         return renderContent(data, index)
                //     }
                // },
                // end - 展示合计

                //begin - 不需要展示合计
                {
                    align: 'center',
                    title: '系统开关状态',
                    dataIndex: 'sysOptStatusDesc',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告组状态',
                    dataIndex: 'adStatusDesc',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告组广告组ID',
                    customRender: (text, row, index) => {
                        return <div>
                                <div>{ row.adGroupName || '-' }</div>
                                <div class="txt">{row.adGroupId || '-'}</div>
                            </div>
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    scopedSlots: { customRender: 'operation' },
                },
                {
                    align: 'center',
                    title: '消费（元）',
                    dataIndex: 'totalCost',
                    customRender: (text, row, index) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '目标转化数',
                    dataIndex: 'totalConvert',
                    customRender: (text, row, index) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                //end - 不需要展示合计

                {
                    align: 'center',
                    title: '展示数',
                    dataIndex: 'totalShow',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击数',
                    dataIndex: 'totalClick',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '展示转化率',
                    dataIndex: 'showConvertRate',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text + '%' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击率',
                    dataIndex: 'clickRate',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text + '%' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击转化率',
                    dataIndex: 'clickConvertRate',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text + '%' : '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 50,
                total: 0
            },
            switchState,
            statusList: [],
            principalList: [],
            seriesList: [],
            selectedRowKeys: [],
            activeIndex: 1,
            detailVisible: false,
            dataDetail: {},
            actionVisible: false,
            rowData: {},
            logsList: [],
            logColumns: [
                {
                    align: 'center',
                    title: '操作时间',
                    dataIndex: 'createTime',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作内容',
                    dataIndex: 'contentTitle',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作前后内容',
                    width: '40%',
                    customRender: (text, row, index) => {
                        let txt = row.contentLog.map(item => {
                            return <div>{item}</div>
                        })
                        return txt
                    }
                },
                {
                    align: 'center',
                    title: '操作人',
                    dataIndex: 'operator',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作IP',
                    dataIndex: 'optIp',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
            ],
            logsLoading: false,
            paginationLog: {
                current: 1,
                pageSize: 10,
                total: 0
            },
        }
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys
                },
                getCheckboxProps: record => ({
                    props: {
                        disabled: record.videoStatus == 3,
                    },
                })
            };
        },
    },
    created() {
        this.getPrincipalList()
        this.getPrincipalSeriesList()
        this.getAdGroupStatus()
        this.getDataList()
    },
    methods: {
        disabledDate(current) {
            return current > this.$moment(this.selectStartDay).add(31, 'days') || current < this.$moment(this.selectStartDay).subtract(31, 'days')
        },
        calendarChange(dates, dateStrings) {
            console.log(dates, dateStrings)
            this.selectStartDay = dates[0]
        },
        openChange(status) {
            this.selectStartDay = ''
        },
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    this.$message.error(`获取主体失败，${res.message}`)
                }
            })
        },
        onChangePrincipal(id) {
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIdList: id,
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    this.$message.error(`获取车系失败，${res.message}`)
                }
            })
        },
        getAdGroupStatus() {
            this.$api.core.JLAdvertManage.getAdGroupStatus().then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.statusList = res.data
                    this.selectStatusList = [res.data[0].code]
                    this.form.adGroupStatusList = this.selectStatusList
                    this.getDataList()
                } else {
                    this.statusList = []
                    this.$message.error(`获取列表失败${res.message}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {
                adGroupStatusList: this.selectStatusList
            }
            this.pickerTime = [moment(), moment()]

            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
            this.seriesList = []
            this.getPrincipalSeriesList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form

            let params = {
                ..._form,
                startTime: `${moment(this.pickerTime[0]).format('YYYY-MM-DD')} 00:00:00`,
                endTime: `${moment(this.pickerTime[1]).format('YYYY-MM-DD')} 23:59:59`,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.JLAdvertManage.getAdGroupList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    this.$message.error(`获取列表失败${res.message}`)
                }
            })
        },
        openDetailDrawer(record, type) {
            this.detailVisible = true
            this.activeIndex = type
            this.rowData = record
            this.getAdGroupDetail(record.id)
            this.getActionLogs(record)
            this.actionVisible = false
        },
        closeDetailDrawer() {
            this.detailVisible = false
            this.dataDetail = {}
            this.rowData = {}
        },
        getAdGroupDetail(id) {
            this.$api.core.JLAdvertManage.getAdGroupDetail(id).then((res) => {
                if(res.code == 200) {
                    this.dataDetail = res.data
                } else {
                    this.dataDetail = {}
                    this.$message.error(`获取详情失败,${res.message}`)
                }
            })
        },
        //批量有效、停用
        batchByAdGroupIds(type) {
            if(this.selectedRowKeys.length < 1) {
                this.$message.error('请选择广告组')
            } else {
                let params = {
                    type,
                    data: this.selectedRowKeys
                }

                this.$api.core.JLAdvertManage.batchByAdGroupIds(params).then((res) => {
                    this.selectedRowKeys = []
                    if(res.code == 200) {
                        this.getDataList()
                        this.$message.success('操作成功')
                    } else {
                        this.$message.error(`操作失败，${res.message}`)
                    }
                })
            }
        },
        jumpPlanPage(id) {
            this.$router.push({
                path: `/advertisingPlanManage`,
                query: {
                    adGroupId: id
                }
            })
        },
        openActionModal(record) {
            this.actionVisible = true
            this.rowData = record
        },
        handleActionCancel() {
            this.actionVisible = false
            this.rowData = {}
        },
        deleteGroup(id) {
            let that = this
            this.$confirm({
                title: '确认删除？',
                okText: '是',
                cancelText: '否',
                onOk() {
                    that.$api.core.JLAdvertManage.deleteAdGroup(id).then((res) => {
                        if(res.code == 200) {
                            that.$message.success('删除成功')
                            that.handleActionCancel()
                            that.getDataList()
                        } else {
                            that.$message.error(`删除失败，${res.message}`)
                        }
                    })
                },
            })
        },
        getActionLogs(record) {
            this.logsLoading = true
            let params = {
                adPlatformAccountId: record.adPlatformAccountId,
                objectId: [record.adGroupId],
                page: this.paginationLog.current,
                pageSize: this.paginationLog.pageSize
            }
            this.$api.core.JLAdvertManage.getActionLogs(params).then((res) => {
                this.logsLoading = false
                if(res.code == 200) {
                    this.logsList = res.data.list || []
                    this.paginationLog.total = res.data.total || 0
                } else {
                    this.logsList = []
                    this.paginationLog.total = 0
                  this.$message.error(`获取操作日志失败，${res.message}`)
                }
            })
        },
        handlePaginationLogChange(current, pageSize) {
            this.paginationLog.current = current
            this.paginationLog.pageSize = pageSize
            this.getActionLogs(this.rowData)

        }
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.mt-40 {
    margin-top: 40px;
}
.txt {
    color: #ccc;
}
.box {
    .title {
        margin: 20px 0 10px;
        font-size: 16px;
        color: #999;
    }
    .row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .name {
        pad: 5px;
        width: 160px;
        text-align: right;
    }
}
</style>
